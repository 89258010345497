@font-face {
  font-family: "Prompt";
  font-weight: 400;
  src: url("./assets/fonts/Prompt-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Prompt";
  font-weight: 600;
  src: url("./assets/fonts/Prompt-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Syne";
  font-weight: 700;
  src: url("./assets/fonts/Syne-Bold.ttf") format("truetype");
  font-display: swap;
}

@keyframes floating1 {
  0% {
    transform: translate(0, -5%);
    transition: ease 0.5s;
  }

  50% {
    transform: translate(0, 5%);
    transition: ease 0.5s;
  }
}

@keyframes floating2 {
  0% {
    transform: translate(0, 0%);
    transition: ease 0.5s;
  }

  25% {
    transform: translate(0, 5%);
    transition: ease 0.5s;
  }

  75% {
    transform: translate(0, -5%);
    transition: ease 0.5s;
  }

  100% {
    transform: translate(0, 0);
    transition: ease 0.5s;
  }
}

@keyframes lethargy {

  0%,
  100% {
    transform: translateY(-5%);
    transition: ease 0.5s;
  }

  50% {
    transform: translateY(0);
    transition: ease 0.5s;
  }
}

@keyframes flicker {

  0%,
  19.999%,
  22%,
  62.999%,
  64%,
  64.999%,
  70%,
  100% {
    opacity: .99;

  }

  20%,
  21.999%,
  63%,
  63.999%,
  65%,
  69.999% {
    opacity: 0.4;
  }
}

.masked-overflow {
  --scrollbar-width: 0px;
  --mask-height: 18%;
  overflow-y: auto;
  --mask-image-content: linear-gradient(to bottom,
      transparent,
      #010103 var(--mask-height),
      #010103 calc(100% - var(--mask-height)),
      transparent);
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(#010103, #010103);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position: 0 0, 100% 0;
  mask-repeat: no-repeat, no-repeat;
}


.masked-overflow-horizontal {
  --scrollbar-width: 0px;
  --mask-height: 18%;
  overflow-y: auto;
  --mask-image-content: linear-gradient(to right,
      transparent,
      #010103 calc(var(--mask-height) - 10%),
      #010103 calc(100% - var(--mask-height)),
      transparent);
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(#010103, #010103);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position: 0 0, 100% 0;
  mask-repeat: no-repeat, no-repeat;
}